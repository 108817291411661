<template>
  <b-container class="mb-5">
    <b-jumbotron bg-variant="secondary" border-variant="dark" text-variant="black">
      <template #header>
        Smart Contract Spammer
      </template>
      <b-button variant="dark">
        Contact:
        <b-button size="sm" variant="primary" href="https://t.me/sharingkala" target="_blank">@sharingkala</b-button>
        <b-button size="sm" variant="primary" href="https://t.me/toriqahmads" target="_blank">@toriqahmads</b-button>
      </b-button>
      <hr />
      <b-button variant="dark">This tool can be used for any chain based on Ethereum</b-button>
      <hr />
      <b-button v-b-modal.modal-1 variant="success">How to use?</b-button>
      <b-modal id="modal-1" title="How to use?">
        <ol>
          <li>Import or create wallet</li>
          <li>Select wallet</li>
          <li>Select or input your own provider</li>
          <li>Connect your wallet</li>
          <li>Input the contract address</li>
          <li>Input the contract ABI and parse</li>
          <li>Now you can interact with contract</li>
        </ol>
        <pre>Only write function can spammed the request</pre>
        <pre>If transaction is success or fail, spamming will be stopped</pre>
      </b-modal>
      <hr />
      <b-alert variant="danger" show>
        <p><b>This tool is beta, use with your own risk!</b></p>
        <p>
          <b>
            We do not LOG, SAVE, SPY or similar actions against your wallet including
            (address, private key, public key, mnemonic), providers, transactions, and all actions!
          </b>
        </p>
        <p>
          <b>
            We are not responsible with your funds.
            Take care and always backup your private key if you create new wallet to use this tool.
            If you refresh the browser, all your recent wallets, providers and transactions will lost
          </b>
        </p>
      </b-alert>
      <!-- <Metamask/> -->
      <b-row>
        <b-col>
        <TriggerWallet/>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <AbiParser :is_vip="true"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <SpamRequest/>
        </b-col>
      </b-row>
    </b-jumbotron>
  </b-container>
</template>

<script>
// @ is an alias to /src
// import Metamask from '@/components/Metamask.vue'
import TriggerWallet from '@/components/TriggerWallet'
import SpamRequest from '@/components/SpamRequest'
import AbiParser from '@/components/AbiParser'

export default {
  name: 'Home',
  components: {
    // Metamask,
    TriggerWallet,
    SpamRequest,
    AbiParser
  }
}
</script>
