import Web3 from 'web3'
import { ethers } from 'ethers'
import providers from '@/constants/providers/providers.json'

const triggerWallet = {
  namespaced: true,
  state: () => ({
    wallet: [],
    current_wallet: null,
    active_provider: null,
    default_providers: providers,
    error: null
  }),
  getters: {
    wallet: (state) => state.wallet,
    current_wallet: (state) => state.current_wallet,
    active_provider: (state) => state.active_provider,
    default_providers: (state) => state.default_providers,
    error: (state) => state.error
  },
  mutations: {
    addWallet (state, wallet) {
      state.wallet = [...state.wallet, wallet]
    },
    useWallet (state, wallet) {
      state.current_wallet = wallet
    },
    setWalletProvider (state, provider) {
      state.active_provider = provider
    },
    error (state, error) {
      state.error = error
    },
  },
  actions: {
    async createWallet ({ commit, dispatch }, payload) {
      try {
        const { use_wallet, host } = payload
        const wallet = ethers.Wallet.createRandom();
        if (!wallet) {
          commit('error', 'Error while generating new wallet')
          return
        }

        commit('addWallet', wallet)
        if (use_wallet) {
          await dispatch('useWallet', { wallet, host })
        }

        return Promise.resolve(wallet)
      } catch (err) {
        commit('error', err.message)
        return Promise.reject(err)
      }
    },
    async importWallet ({ commit, dispatch }, payload) {
      try {
        const { private_key, use_wallet, host } = payload
        const wallet = new ethers.Wallet(private_key)
        if (!wallet) {
          commit('error', 'Error while importing wallet')
          return
        }

        commit('addWallet', wallet)
        if (use_wallet) {
          await dispatch('useWallet', { wallet, host })
        }

        return Promise.resolve(wallet);
      } catch (err) {
        commit('error', err.message)
        return Promise.reject(err)
      }
    },
    async useWallet ({ commit }, payload) {
      try {
        let provider
        let { host, wallet } = payload
        if (host.startsWith('http')) {
          provider = new Web3.providers.HttpProvider(host)

          provider = new ethers.providers.Web3Provider(provider)
        }
        if (host.startsWith('ws')) {
          provider = new Web3.providers.WebsocketProvider(host, {
            timeout: 10000,
            clientConfig: {
              keepalive: true,
              keepaliveInterval: 2000
            },
            reconnect: {
              auto: true,
              delay: 1000,
              maxAttempts: 5,
              onTimeout: true
            },
            reconnectDelay: 1
          })

          provider = new ethers.providers.WebSocketProvider(provider)
        }

        wallet = wallet.connect(provider)

        commit('useWallet', wallet)
        commit('setWalletProvider', provider)

        this.dispatch('spamRequest/initSpamWorker', {
          active_provider: provider,
          current_wallet: wallet
        })

        return wallet
      } catch (err) {
        commit('error', err.message)
        return false
      }
    }
  }
}

export default triggerWallet
