<template>
  <div>
    <b-card border-variant="dark" header="Contract interaction" class="m-3">
      <b-card-body>
        <div v-if="error && error != ''">
          {{ error }}
        </div>
        <div v-if="error_spam && error_spam != ''">
          {{ error_spam }}
        </div>

        <b-row>
          <b-col lg="8" cols="12">
            <b-input-group prepend="Default ABI">
              <b-form-select
                v-model="choose_abi"
                class="form-select"
                :options="default_abi_option"
              />
            </b-input-group>
          </b-col>
          <b-col>
            <b-button v-on:click="chooseAbi">Choose ABI</b-button>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col lg="4" xs="12" class="mb-1">
            <b-form-group label="Contract address:">
              <b-form-input v-model="contract_address" placeholder="contract address..."></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="8" xs="12" class="mb-1">
            <b-form-group label="Contract ABI:">
              <b-form-textarea
                id="textarea"
                placeholder="Paste JSON ABI here..."
                rows="3"
                max-rows="6"
                v-model="abi"
                v-on:input="isValidJson(abi)"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col></b-col>
        </b-row>
        <b-row>
          <b-button class="mt-3 w-100" variant="primary" v-on:click="parseAbi">Parse</b-button>
        </b-row>

        <template v-if="functions.length > 0">
          <hr>
          <b-tabs pills card class="border border-secondary rounded">
            <b-tab title="Read" active>
              <div v-for="(func, func_index) in functions" v-bind:key="func_index">
                <b-card v-if="isViewFunction(func) && func.type != 'receive' && func.type !='constructor'" v-bind:header="func.name">
                  <b-card-body>
                    <b-row>
                      <b-col>
                        <b-form-group
                          v-for="(input, input_index) in func.inputs"
                          v-bind:key="input_index"
                          v-bind:label="input.name"
                        >
                          <b-form-input
                            v-if="getType(input.type).type != 'checkbox'"
                            :type="getType(input.type).type"
                            v-bind:placeholder="input.type"
                            v-model="func.inputs[input_index].value"
                            v-on:input="isValidInput(func_index, input_index)"
                          >
                          </b-form-input>
                          <b-form-checkbox
                            v-if="getType(input.type).type == 'checkbox'"
                            v-bind:placeholder="input.type"
                            v-model="func.inputs[input_index].value"
                            v-on:input="isValidInput(func_index, input_index)"
                          >
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <hr />
                    <b-button variant="info" v-on:click="read(func)">Read</b-button>
                  </b-card-body>
                  <b-card-footer v-if="hasOutput(func)">
                    <b-list-group>
                      <div
                        v-for="(output, output_index) in func.outputs"
                        v-bind:key="output_index"
                      >
                        <b-list-group-item v-if="output.value && output.value != ''">
                          <template v-if="Array.isArray(output.value) && output.value.length > 0">
                            <b-list-group-item v-for="(out, idx) in output.value" v-bind:key="idx">
                              {{ out }}
                            </b-list-group-item>
                          </template>
                          <template v-if="!Array.isArray(output.value)">
                            {{ output.value }}
                          </template>
                        </b-list-group-item>
                      </div>
                    </b-list-group>
                  </b-card-footer>
                </b-card>
                <hr v-if="isViewFunction(func)" />
              </div>
            </b-tab>
            <b-tab title="Write">
              <div v-for="(func, func_index) in functions" v-bind:key="func_index">
                <b-card v-if="isWriteFunction(func)" v-bind:header="func.name">
                  <b-card-body>
                    <b-row>
                      <b-col
                        v-if="func.stateMutability == 'payable'"
                        xs="12"
                        cols="12"
                      >
                        <b-form-group label="Amount Pay">
                          <b-form-input
                            v-model="func.amount_eth"
                            type="number"
                            placeholder="amount eth"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col
                        xs="12"
                        cols="12"
                      >
                        <b-form-group
                          v-for="(input, input_index) in func.inputs"
                          v-bind:key="input_index"
                          v-bind:label="input.name"
                        >
                          <b-form-input
                            v-if="getType(input.type).type != 'checkbox'"
                            :type="getType(input.type).type"
                            v-bind:placeholder="input.type"
                            v-model="func.inputs[input_index].value"
                            v-on:input="isValidInput(func_index, input_index)"
                          ></b-form-input>
                          <b-form-checkbox
                            v-if="getType(input.type).type == 'checkbox'"
                            v-bind:placeholder="input.type"
                            v-model="func.inputs[input_index].value"
                            v-on:input="isValidInput(func_index, input_index)"
                          ></b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <hr />
                    <b-row>
                      <b-form-group
                        v-bind:key="`${func.name}_custom_gas`"
                        label="Custom Gas Price?"
                      >
                        <b-form-checkbox
                          v-model="func.custom_gas"
                          v-on:change="customGas(func)"
                        ></b-form-checkbox>
                      </b-form-group>
                      <b-row v-if="func.custom_gas">
                        <b-col>
                          <b-form-group
                            v-bind:key="`${func.name}_gas_price`"
                            label="Gas Price"
                          >
                            <b-form-input
                              type="number"
                              placeholder="Input gas price in gwei"
                              v-model="func.gas_price"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            v-bind:key="`${func.name}_gas_limit`"
                            label="Gas Limit"
                          >
                          <b-form-input
                              type="number"
                              placeholder="Input gas limit"
                              v-model="func.gas_limit"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-row>
                    <template v-if="is_vip">
                    <hr />
                    <b-row>
                      <b-col>
                        <b-form-group
                          v-bind:key="`${func.name}_tx_from`"
                          label="Tx From"
                        >
                        <b-form-input
                            type="text"
                            placeholder="0x...."
                            v-model="func.tx_from"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          v-bind:key="`${func.name}_tx_to`"
                          label="Tx To"
                        >
                        <b-form-input
                            type="text"
                            placeholder="0x...."
                            v-model="func.tx_to"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          v-bind:key="`${func.name}_tx_function`"
                          label="Tx Function"
                        >
                        <b-form-input
                            type="text"
                            placeholder="someFunction(arg1,arg2,arg3,...)|someFunction2(arg1,arg2,arg3,...)"
                            v-model="func.tx_function"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-button class="mt-3" variant="warning" v-on:click="startListen(func, listen_started)">
                          {{ listen }}
                        </b-button>
                      </b-col>
                    </b-row>
                    </template>
                    <hr />
                    <b-row>
                      <b-col>
                        <b-button variant="primary" v-on:click="process(func)">Write</b-button>
                      </b-col>
                      <b-col>
                        <b-button variant="danger" v-on:click="process(func, true, spam_started)">
                          {{ spam }}
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card-body>
                  <b-card-footer v-if="hasOutput(func)">
                    <b-list-group>
                      <div
                        v-for="(output_write, output_index_write) in func.outputs"
                        v-bind:key="output_index_write"
                      >
                        <b-list-group-item v-if="output_write.value && output_write.value != ''">
                          <template v-if="Array.isArray(output_write.value) && output_write.value.length > 0">
                            <b-list-group-item v-for="(out_write, idx2) in output_write.value" v-bind:key="idx2">
                              {{ out_write }}
                            </b-list-group-item>
                          </template>
                          <template v-if="!Array.isArray(output_write.value)">
                            {{ output_write.value }}
                          </template>
                        </b-list-group-item>
                      </div>
                    </b-list-group>
                  </b-card-footer>
                </b-card>
                <hr v-if="isWriteFunction(func)" />
              </div>
            </b-tab>
          </b-tabs>
        </template>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ethers } from 'ethers'
import { stringToArray } from '@/helpers/common'
import { isAddress } from '@/helpers/addressUtils'
import {
  parseJSON,
  parseABI,
  getType as getInputType,
  isContractArgValid
} from '@/handlers/contract/abi-parser'
import defaults_abi from '@/constants/abi/default-abi.json'

export default {
  name: 'AbiParser',
  props: [
    'is_vip'
  ],
  data() {
    return {
      error: '',
      functions: '',
      abi: '',
      contract_address: '',
      choose_abi: '',
      defaults_abi
    }
  },
  computed: {
    ...mapGetters('spamRequest', {
      worker: 'worker',
      spam_started: 'spam_started',
      listen_started: 'listen_started',
      error_spam: 'error'
    }),
    spam () {
      return this.spam_started ? 'Stop spam' : 'Start spam'
    },
    listen () {
      return this.listen_started ? 'Stop listen' : 'Start listen'
    },
    default_abi_option () {
      const re = [
        { value: null, text: 'Please select one', disabled: true }
      ]
      if (this.defaults_abi !== []) {
        this.defaults_abi.forEach((abi, idx) => {
          re.push({ value: idx, text: abi.name })
        })
      }
      return re
    },
  },
  methods: {
    parseAbi () {
      try {
        const functions = parseABI(parseJSON(this.abi))
        if (functions) {
          this.functions = functions.map((func) => {
            func.gas_price = ''
            func.gas_limit = ''
            func.tx_from = ''
            func.tx_to = ''
            func.tx_function = ''
            func.custom_gas = false
            return func
          })
        } else {
          this.functions = ''
        }
      } catch (err) {
        this.error = err.message
      }
    },
    getType (type) {
      return getInputType(type)
    },
    isValidInput (func_index, input_index) {
      return isContractArgValid(this.functions[func_index].inputs[input_index].value, this.functions[func_index].inputs[input_index].type)
    },
    payableInput (amount_eth) {
      if (!amount_eth || amount_eth === '') amount_eth = '0'
      return ethers.utils.parseUnits((amount_eth).toString(), 'ether')
    },
    isViewFunction (func) {
      return func.constant || func.stateMutability === 'view'
    },
    isWriteFunction (func) {
      return func.type === 'function' && !this.isViewFunction(func)
    },
    isValidJson (json) {
      if (!json || json == '') {
        this.error = ''
        return
      }

      try {
        JSON.parse(json)
        this.error = ''
        return true
      } catch (e) {
        this.error = 'please input a valid JSON ABI'
        return false
      }
    },
    customGas (func) {
      if (!func.custom_gas) {
        func.gas_price = ''
        func.gas_limit = ''
      }
    },
    async startListen (func, stop_listen) {
      if (!this.contract_address || this.contract_address == '' || !isAddress(this.contract_address)) {
        alert('Please input a valid contract address')
        return
      }

      await this.$store.dispatch('spamRequest/setContractAbi', this.abi)
      let amount_eth
      const params = []

      if (func.amount_eth) amount_eth = this.payableInput(func.amount_eth)
      if (func.inputs && func.inputs.length > 0) {
        func.inputs.map((input) => {
          if (input.value && input.value != '') {
            if (input.type.includes('[]')) params.push(stringToArray(input.value))
            else params.push(input.value)
          }
        })
      }

      if (!stop_listen) {
        await this.$store.dispatch('spamRequest/startListen', {
          to: this.contract_address,
          function_name: func.name,
          value: amount_eth,
          gas_limit: func.gas_limit,
          gas_price: func.gas_price,
          filter: {
            tx_from: func.tx_from,
            tx_to: func.tx_to,
            tx_function: func.tx_function,
          },
          params,
        })

        func.listen_started = true
      }

      if (stop_listen) {
        await this.stopListen()
      }
    },
    async process (func, spam, stop_spam) {
      if (!this.contract_address || this.contract_address == '' || !isAddress(this.contract_address)) {
        alert('Please input a valid contract address')
        return
      }

      await this.$store.dispatch('spamRequest/setContractAbi', this.abi)
      let amount_eth
      const params = []

      if (func.amount_eth) amount_eth = this.payableInput(func.amount_eth)
      if (func.inputs && func.inputs.length > 0) {
        func.inputs.map((input) => {
          if (input.value && input.value != '') {
            if (input.type.includes('[]')) params.push(stringToArray(input.value))
            else params.push(input.value)
          }
        })
      }

      if (spam && !stop_spam) {
        await this.$store.dispatch('spamRequest/startSpam', {
          to: this.contract_address,
          function_name: func.name,
          value: amount_eth,
          gas_limit: func.gas_limit,
          gas_price: func.gas_price,
          params,
        })

        func.spam_started = true
      }

      if (!spam && !stop_spam) {
        let transaction = await this.$store.dispatch('spamRequest/writeTx', {
          to: this.contract_address,
          function_name: func.name,
          gas_limit: func.gas_limit,
          gas_price: func.gas_price,
          value: amount_eth,
          params,
        })

        if (func.outputs && func.outputs.length > 0) {
          if (!Array.isArray(transaction)) transaction = [transaction]

          func.outputs = func.outputs.map((out, index) => {
            out.value = transaction[index]
            return out
          })
        }
      }

      if (spam && stop_spam) {
        await this.stopSpam()
      }
    },
    async read (func) {
      if (!this.contract_address || this.contract_address == '' || !isAddress(this.contract_address)) {
        alert('Please input a valid contract address')
        return
      }

      await this.$store.dispatch('spamRequest/setContractAbi', this.abi)

      const params = []
      if (func.inputs && func.inputs.length > 0) {
        func.inputs.map((input) => {
          if (input.value && input.value != '') {
            if (input.type.includes('[]')) params.push(stringToArray(input.value))
            else params.push(input.value)
          }
        })
      }

      let transaction = await this.$store.dispatch('spamRequest/readTx', {
        to: this.contract_address,
        function_name: func.name,
        params,
      })

      if (func.outputs && func.outputs.length > 0) {
        if (!Array.isArray(transaction)) transaction = [transaction]

        func.outputs = func.outputs.map((out, index) => {
          out.value = transaction[index]
          return out
        })
      }
    },
    hasOutput (func) {
      let has_output = false
      if (func.outputs && func.outputs.length > 0) {
        has_output = func.outputs.some((out) => out.value && out.value != '')
      }

      return has_output
    },
    async stopSpam () {
      try {
        await this.$store.dispatch('spamRequest/stopSpam')
      } catch (err) {
        this.error = err.message
      }
    },
    async stopListen () {
      try {
        await this.$store.dispatch('spamRequest/stopListen')
      } catch (err) {
        this.error = err.message
      }
    },
    getSpamStatus (func) {
      return func.spam_started
    },
    getListenStatus (func) {
      return func.listen_started
    },
    chooseAbi () {
      this.contract_address = this.defaults_abi[this.choose_abi].address
      this.abi = JSON.stringify(this.defaults_abi[this.choose_abi].abi)
    }
  },
  async mounted() {
  }
}
</script>
