import Vue from 'vue'
import Vuex from 'vuex'

import ethereum from './modules/ethereum'
import contract from './modules/contract'
import triggerWallet from './modules/trigger-wallet'
import spamRequest from './modules/spam-request'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    ethereum,
    contract,
    triggerWallet,
    spamRequest
  }
})
