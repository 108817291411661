import { ethers } from 'ethers'
import abi from '@/constants/abi/contract.json'

const contract = {
  namespaced: true,
  state: () => ({
    contract: null,
    error: null
  }),
  getters: {
    contract: (state) => state.contract,
    error: (state) => state.error
  },
  mutations: {
    initSuccess (state, contract) {
      state.contract = contract
    },
    initError (state, error) {
      state.error = error
    }
  },
  actions: {
    async init ({ commit }) {
      try {
        const signer = this.getters['ethereum/signer']
        console.log('signer', signer)
        if (!signer) {
          commit('initError', 'Account not connected')
          return
        }

        const contract = new ethers.Contract('0xfB68d9c59DBD6324583BF815e0758cD7634051cE', abi, signer)

        commit('initSuccess', contract)
      } catch (err) {
        console.log('err', err)
        commit('initError', err.message)
      }
    },
    async isHoneyPot ({ state }, payload) {
      try {
        const is_honeypot = await state.contract.isHoneypot(payload, {
          value: ethers.utils.parseUnits((0.001).toString(), 'ether')
        })
        console.log('isHoneypot', is_honeypot)

        return Promise.resolve(is_honeypot);
      } catch (err) {
        console.log('err', err)
        return Promise.reject(err)
      }
    }
  }
}

export default contract
