<template>
  <div>
    <b-card border-variant="dark" header="Wallets" class="m-3">
      <b-list-group>
        <b-list-group-item v-if="address">Address: {{ address }}</b-list-group-item>
        <b-list-group-item v-if="new_private_key">Private key: {{ new_private_key }}</b-list-group-item>
      </b-list-group>
      <b-list-group>
        <b-list-group-item v-if="error">{{ error }}</b-list-group-item>
      </b-list-group>
      <hr v-if="error || address || new_private_key"/>

      <b-card header="Create or import your wallet">
        <b-card-body>
          <b-form-group>
            <b-row>
              <b-col lg="8" xs="12" class="mb-1">
                <b-form-group>
                  <b-row>
                    <b-col lg="8" xs="12" class="mb-1">
                      <b-input-group prepend="Private Key">
                        <b-form-input v-model="private_key" id="private_key" placeholder="Input your private key" type="password"></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col lg="4" xs="12" class="mb-1">
                      <b-button v-on:click='importWallet' variant="info">Import</b-button>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col lg="1" xs="12" class="mb-1">
                <span>OR</span>
              </b-col>
              <b-col lg="3" xs="12" class="mb-1">
                <b-form-group>
                  <b-row>
                    <b-col>
                      <b-button v-on:click='createWallet' variant="primary">Create</b-button>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form-group>
        </b-card-body>
      </b-card>

      <template v-if="wallet.length > 0">
        <hr>
        <b-card header="Choose and connect your wallet">
          <b-row>
            <b-col lg="12" cols="12">
              <b-button
                class="mt-3"
                variant="info"
                v-b-modal.modal-2
              >
                Want to connect provider with HTTP or WS?
              </b-button>
              <b-modal id="modal-2" title="Want to connect provider with HTTP or WS?">
                <pre>Sorry, you can't use HTTP or WS provider</pre>
                <pre>Change your provider to HTTPS or WSS</pre>
                <pre>Thank you!</pre>
              </b-modal>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col lg="5" cols="12">
              <b-form-group label="Wallets:">
                <b-row>
                  <b-col>
                    <b-form-select
                      v-model="choosen_wallet"
                      class="form-select"
                      :options="wallet_options"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>

            <b-col lg="7" cols="12">
              <b-form-group label="Providers:">
                <b-row>
                  <b-col lg="7" cols="12">
                    <b-form-select
                      v-if="!use_own_provider"
                      v-model="host"
                      class="form-select"
                      :options="use_own_provider_options"
                    />
                    <b-form-input v-else v-model="host" type="text" placeholder="https://"></b-form-input>
                  </b-col>
                  <b-col lg="5" cols="12">
                    <b-form-checkbox v-model="use_own_provider">Use your own provider?</b-form-checkbox>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" cols="12">
              <b-row>
                <b-col>
                  <b-button class="mt-3" variant="primary" v-on:click="connectWallet">Connect</b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </template>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'TriggerWallet',
  data() {
    return {
      private_key: '',
      address: '',
      new_private_key: '',
      use_wallet: false,
      use_own_provider: false,
      host: null,
      error: '',
      choosen_wallet: null
    }
  },
  computed: {
    ...mapGetters('triggerWallet', {
      wallet: 'wallet',
      current_wallet: 'current_wallet',
      error_wallet: 'error',
      default_providers: 'default_providers'
    }),
    wallet_options () {
      const re = [
        { value: null, text: 'Please select one', disabled: true }
      ]
      if (this.wallet !== []) {
        this.wallet.forEach((wal, idx) => {
          re.push({ value: idx, text: wal.address })
        })
      }
      return re
    },
    use_own_provider_options () {
      const re = [
        { value: null, text: 'Please select one', disabled: true }
      ]
      if (this.default_providers !== []) {
        this.default_providers.forEach((wal) => {
          re.push({ value: wal.network, text: `${wal.network_name} - ${wal.network}` })
        })
      }
      return re
    }
  },
  methods: {
    async createWallet () {
      try {
        const wallet = await this.$store.dispatch('triggerWallet/createWallet', {
          use_wallet: this.use_wallet,
          host: this.host
        })
        this.new_private_key = wallet.privateKey
        this.address = wallet.address
      } catch (err) {
        this.error = err.message
      }
    },
    async importWallet () {
      this.error = ''
      try {
        const wallet = await this.$store.dispatch('triggerWallet/importWallet', {
          private_key: this.private_key,
          use_wallet: this.use_wallet,
          host: this.host
        })
        this.new_private_key = wallet.privateKey
        this.address = wallet.address
      } catch (err) {
        this.error = err.message
      }
    },
    async connectWallet () {
      try {
        const wallet = await this.$store.dispatch('triggerWallet/useWallet', {
          wallet: this.wallet[this.choosen_wallet],
          host: this.host
        })
        if (!wallet) return
        this.new_private_key = wallet.privateKey
        this.address = wallet.address
        alert('success connect')
      } catch (err) {
        this.error = err.message
      }
    },
  },
  async mounted() {
  }
}
</script>
