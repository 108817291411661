import store from '@/store'
import { ethers } from 'ethers'
import {
  parseJSON,
  parseABI
} from '@/handlers/contract/abi-parser'

export default class SpamWorker {
  wallet = null
  provider = null
  contractAbi = null
  spamStarted = false

  constructor (wallet, provider, abi = null) {
    this.wallet = wallet
    this.provider = provider
    if (abi) this.contractAbi = new ethers.utils.Interface(abi)
  }

  setContractAbi (abi) {
    abi = parseABI(parseJSON(abi))
    this.contractAbi = new ethers.utils.Interface(abi)
  }

  pausePattern (pause) {
    pause = pause.split('|');
    let pattern = `^(`;
    let first = true;
    for (let p of pause) {
      const hash = ethers.utils.id(p).substring(0, 10);
      if (!first) pattern += `|`;
      pattern += `${hash}`;
      first = false;
    }

    return `${pattern})`;
  }

  startListen ({ from, to, value, gas_price, gas_limit, function_name, params, filter }) {
    try {
      const data = this.contractAbi.encodeFunctionData(function_name, params)
      const tx = {
        to,
        data
      }

      if (value) tx.value = value
      if (gas_price && gas_price != '') {
        tx.gasPrice = ethers.utils.parseUnits(gas_price, 'gwei')
      }
      if (!from) tx.from = this.wallet.address
      if (from) tx.from = from
      if (gas_limit && gas_limit != '') {
        const gasLimit = ethers.BigNumber.from(gas_limit)
        tx.gasLimit = ethers.utils.hexValue(gasLimit)
      } else {
        try {
          tx.gasLimit = this.wallet.estimateGas(tx);
        } catch (errGas) {
          this.spamStarted = false
          const error_log = errGas.code && errGas.code != '' ? errGas.code : errGas.message
          this.stopListen()
          store.commit('spamRequest/error', errGas.message)
          store.commit('spamRequest/log', error_log)
          alert(`${error_log} - Please set Gas Limit!`)
        }
      }

      if (filter) {
        if (filter.tx_from && filter.tx_from != '') {
          filter.tx_from = ethers.utils.getAddress(filter.tx_from)
        }
        if (filter.tx_to && filter.tx_to != '') {
          filter.tx_to = ethers.utils.getAddress(filter.tx_to)
        }
        if (filter.tx_function && filter.tx_function != '') {
          const pause_pattern = this.pausePattern(filter.tx_function);
          filter.tx_function = new RegExp(pause_pattern, 'gmi');
        }
      }

      this.provider.on('pending', async (txhash) => {
        if (typeof txhash == 'string') {
          txhash = await this.provider.getTransaction(txhash)
        }

        let passed = true
        if (filter.tx_to && filter.tx_to != '') {
          if (filter.tx_to != txhash.to) passed = false
        }
        if (filter.tx_from && filter.tx_from != '') {
          if (filter.tx_from != txhash.from) passed = false
        }
        if (filter.tx_function && filter.tx_function != '') {
          const check = filter.tx_function.test(txhash.data)
          if (!check) passed = false
        }

        console.log('passed', passed)
        if (passed) {
          try {
            const transaction = await this.wallet.sendTransaction(tx)
            console.log('transaction success send', transaction.hash)
            store.commit('spamRequest/transaction', transaction.hash)
            return this.stopListen()
          } catch (err2) {
            this.spamStarted = false
            const error_log = err2.code && err2.code != '' ? err2.code : err2.message
            this.stopListen()
            store.commit('spamRequest/error', err2.message)
            store.commit('spamRequest/log', error_log)
            alert(`Failed executing your transaction ${error_log}`)
          }
        }

        store.commit('spamRequest/log', txhash.hash)
      })

      store.commit('spamRequest/listen_started', true)
    } catch (err) {
      this.spamStarted = false
      const error_log = err.code && err.code != '' ? err.code : err.message
      this.stopListen()
      store.commit('spamRequest/error', err.message)
      store.commit('spamRequest/log', error_log)
    }
  }

  stopListen () {
    this.provider.off('pending')
    store.commit('spamRequest/listen_started', false)
  }

  async startSpam ({ from, to, value, gas_price, gas_limit, function_name, params }) {
    try {
      const data = this.contractAbi.encodeFunctionData(function_name, params)
      const tx = {
        to,
        data
      }

      if (value) tx.value = value
      if (!from) tx.from = this.wallet.address
      if (from) tx.from = from
      if (gas_limit && gas_limit != '') {
        const gasLimit = ethers.BigNumber.from(gas_limit)
        tx.gasLimit = ethers.utils.hexValue(gasLimit)
      } else {
        try {
          tx.gasLimit = this.wallet.estimateGas(tx);
        } catch (errGas) {
          this.spamStarted = false
          const error_log = errGas.code && errGas.code != '' ? errGas.code : errGas.message
          store.commit('spamRequest/spam_started', this.spamStarted)
          store.commit('spamRequest/error', errGas.message)
          store.commit('spamRequest/log', error_log)
          alert(`${error_log} - Please set Gas Limit!`)
          return Promise.reject(errGas)
        }
      }

      let transaction
      this.spamStarted = true
      store.commit('spamRequest/spam_started', this.spamStarted)

      while (this.spamStarted) {
        let simulate = false
        try {
          if (tx.value && tx.value._hex) {
            tx.value = tx.value._hex.startsWith('0x0') ? `0x${tx.value._hex.substring(3)}` : tx.value._hex
          }

          await this.wallet.call(tx, 'latest')
          simulate = true
        } catch (e) {
          store.commit('spamRequest/log', e.code)
          simulate = false
        }

        if (simulate) {
          try {
            if (value) tx.value = value
            if (gas_price && gas_price != '') {
              tx.gasPrice = ethers.utils.parseUnits(gas_price, 'gwei')
            }

            transaction = await this.wallet.sendTransaction(tx)
            this.spamStarted = false
          } catch (e2) {
            store.commit('spamRequest/log', e2.code)
            this.spamStarted = false
          }
        }
      }

      if (transaction && transaction.hash) {
        store.commit('spamRequest/spam_started', this.spamStarted)
        store.commit('spamRequest/transaction', transaction.hash)
        return Promise.resolve(transaction)
      }

      store.commit('spamRequest/spam_started', this.spamStarted)
      return Promise.resolve('Spam stopped')
    } catch (err) {
      this.spamStarted = false
      const error_log = err.code && err.code != '' ? err.code : err.message
      store.commit('spamRequest/spam_started', this.spamStarted)
      store.commit('spamRequest/error', err.message)
      store.commit('spamRequest/log', error_log)
      return Promise.reject(err)
    }
  }

  async stopSpam () {
    try {
      this.spamStarted = false
      store.commit('spamRequest/spam_started', this.spamStarted)
      return this.spamStarted
    } catch (err) {
      store.commit('spamRequest/error', err.message)
      return this.spamStarted
    }
  }

  async writeTx ({ from, to, value, gas_price, gas_limit, function_name, params }) {
    try {
      const data = this.contractAbi.encodeFunctionData(function_name, params)
      const tx = {
        to,
        data
      }

      if (value) tx.value = value
      if (gas_limit && gas_limit != '') {
        const gasLimit = ethers.BigNumber.from(gas_limit)
        tx.gasLimit = ethers.utils.hexValue(gasLimit)
      }
      if (gas_price && gas_price != '') {
        tx.gasPrice = ethers.utils.parseUnits(gas_price, 'gwei')
      }
      if (from) tx.from = from
      else tx.from = this.wallet.address

      const transaction = await this.wallet.sendTransaction(tx)

      store.commit('spamRequest/transaction', transaction.hash)

      return Promise.resolve(transaction.hash)
    } catch (err) {
      const error_log = err.code && err.code != '' ? err.code : err.message
      store.commit('spamRequest/log', error_log)
      return Promise.reject(error_log)
    }
  }

  async readTx ({ from, to, gas_price, gas_limit, function_name, params }) {
    try {
      const data = this.contractAbi.encodeFunctionData(function_name, params)
      const tx = {
        to,
        data
      }

      if (gas_limit && gas_limit != '') tx.gasLimit = ethers.utils.hexlify(gas_limit)
      if (gas_price && gas_price != '') tx.gasPrice = ethers.utils.parseUnits(gas_price, 'gwei')
      if (from) tx.from = from
      else tx.from = this.wallet.address
      const transaction = await this.wallet.call(tx, 'latest')
      const result = this.contractAbi.decodeFunctionResult(function_name, transaction)

      return Promise.resolve(result[0])
    } catch (err) {
      const error_log = err.code && err.code != '' ? err.code : err.message
      return Promise.reject(error_log)
    }
  }
}
