<template>
  <div class="mx-3 mt-3">
    <!-- <button v-on:click="startSpam">Start spam</button><br/>
    <button v-on:click="stopSpam">Stop spam</button><br/> -->
    <b-card header="Transaction Log">
      <b-card-body>
        <b-row>
          <b-col lg="6" xs="12" class="mb-1">
            <b-form-group
              label="Spam Error Log:"
            >
              <b-textarea
                id="logs"
                rows="6"
                max-rows="6"
                v-bind:value="logs"
                disabled
              >
              </b-textarea>
            </b-form-group>
          </b-col>
          <b-col lg="6" xs="12" class="mb-1">
            <b-form-group
              label="Transaction Log:"
            >
              <b-textarea
                id="transactions"
                rows="6"
                max-rows="6"
                v-bind:value="transactions"
                disabled
              >
              </b-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SpamWorker from '../utils/spam-request'

export default {
  name: 'SpamRequest',
  data() {
    return {
      error: '',
      worker: null
    }
  },
  computed: {
    ...mapGetters('triggerWallet', {
      current_wallet: 'current_wallet',
      error_wallet: 'error',
      active_provider: 'active_provider'
    }),
    ...mapGetters('spamRequest', {
      logs: 'logs',
      transactions: 'transactions',
      error_spam: 'error'
    }),
    alert_error_spam () {
      if (this.error_spam) {
        alert(this.error_spam)
      }
      return this.error_spam
    },
    log () {
      if (this.logs) {
        let ta = document.getElementById('logs')
        console.log('ta2', ta)
        ta.scrollTop = ta.scrollHeight
        return this.logs
      }
      return ''
    },
    transaction () {
      if (this.transactions) {
        let ta = document.getElementById('transactions')
        ta.scrollTop = ta.scrollHeight
        return this.transactions
      }
      return ''
    }
  },
  methods: {
    async startSpam() {
      if (!this.active_provider || !this.current_wallet) return
      this.worker = new SpamWorker(this.current_wallet, this.active_provider)
      this.worker.start()
    },
    async stopSpam() {
      if (!this.worker) return
      this.worker.stop()
    }
  },
  async mounted() {
  }
}
</script>
