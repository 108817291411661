import SpamWorker from '@/utils/spam-request'

const spamRequest = {
  namespaced: true,
  state: () => ({
    worker: null,
    logs: '',
    transactions: '',
    error: null,
    spam_started: false,
    listen_started: false
  }),
  getters: {
    worker: (state) => state.worker,
    logs: (state) => state.logs,
    transactions: (state) => state.transactions,
    spam_started: (state) => state.spam_started,
    listen_started: (state) => state.listen_started,
    error: (state) => state.error
  },
  mutations: {
    worker (state, worker) {
      state.worker = worker
    },
    spam_started (state, spam_started) {
      state.spam_started = spam_started
    },
    listen_started (state, listen_started) {
      state.listen_started = listen_started
    },
    log (state, log) {
      state.logs = state.logs.concat('\n', log)
    },
    transaction (state, transaction) {
      state.transactions = state.transactions.concat('\n', transaction)
      alert(`Tx success: ${transaction}`)
    },
    error (state, error) {
      state.error = error
    },
  },
  actions: {
    initSpamWorker ({ commit }, payload) {
      if (!payload.active_provider || !payload.current_wallet) {
        commit('error', 'provider or wallet not set')
        return false
      }

      const worker = new SpamWorker(payload.current_wallet, payload.active_provider)
      commit('worker', worker)
      return worker
    },
    async setContractAbi ({ commit, dispatch }, abi) {
      const worker = await dispatch('getWorker')
      worker.setContractAbi(abi)
      commit('worker', worker)
    },
    getWorker ({ commit }) {
      const worker = this.getters['spamRequest/worker']
      if (!worker) {
        commit('error', 'provider or wallet not set')
        return false
      }
      return worker
    },
    async startListen ({ dispatch }, payload) {
      try {
        const worker = await dispatch('getWorker')
        worker.startListen(payload)
      } catch (err) {
        alert(err.message)
      }
    },
    async stopListen ({ dispatch, commit }) {
      try {
        const worker = await dispatch('getWorker')
        const stop = await worker.stopListen()
        return Promise.resolve(stop)
      } catch (err) {
        commit('error', err.message)
        return Promise.reject(err.message)
      }
    },
    async startSpam ({ dispatch }, payload) {
      try {
        const worker = await dispatch('getWorker')
        worker.startSpam(payload)
      } catch (err) {
        alert(err.message)
      }
    },
    async stopSpam ({ dispatch, commit }) {
      try {
        const worker = await dispatch('getWorker')
        const stop = await worker.stopSpam()
        return Promise.resolve(stop)
      } catch (err) {
        commit('error', err.message)
        return Promise.reject(err.message)
      }
    },
    async writeTx ({ dispatch }, payload) {
      try {
        const worker = await dispatch('getWorker')
        const tx = await worker.writeTx(payload)

        return tx
      } catch (err) {
        return err
      }
    },
    async readTx ({ dispatch }, payload) {
      try {
        const worker = await dispatch('getWorker')
        const tx = await worker.readTx(payload)

        return tx
      } catch (err) {
        return err
      }
    },
  }
}

export default spamRequest
